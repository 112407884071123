<template>
  <v-container class="pa-4" fluid>
    <v-row class="text-center" no-gutters>
      <v-col cols="12">
        <v-img alt="PARKING.COM" class="my-3" height="42" contain src="../assets/logo_huge_drk.png" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card class="mx-auto rounded-lg" max-width="374">
          <v-dialog v-model="loading" persistent>
            <v-card class="pa-4">
              <v-progress-circular indeterminate color="red"></v-progress-circular>
              <span class="ml-4" style="font-size: 14px">Please wait...</span>
            </v-card>
          </v-dialog>
          <!-- <v-responsive :aspect-ratio="16/9"> -->
          <template slot="progress">
            <v-progress-linear color="red" height="10" indeterminate></v-progress-linear>
          </template>
          <div style="position: relative">
            <!-- <v-skeleton-loader
                v-show="!bookingDetails.hasOwnProperty('zone')"
                height="260px"
                class="rounded-t-lg"
                type="image"
              ></v-skeleton-loader> -->

            <v-img class="rounded-t-lg" max-height="150px" style="filter: brightness(50%)" :src="bookingDetails.hasOwnProperty('zone') && bookingDetails.zone.image != '' && bookingDetails.zone.image != null
              ? bookingDetails.zone.image
              : zoneImgURL
              " :lazy-src="bookingDetails.hasOwnProperty('zone') && bookingDetails.zone.image != '' && bookingDetails.zone.image != null
                ? bookingDetails.zone.image
                : zoneImgURL
                " alt="Parking.com garage">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center" no-gutters>
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <div class="booking_header white--text text-left mr-4 mb-4">
              Hi
              {{
                Object.hasOwnProperty.call(bookingDetails, 'user') && Object.hasOwnProperty.call(bookingDetails.user,
                  'name')
                  ? bookingDetails.user.name
                  : ""
              }},
            </div>
            <!-- <div class="booking_divider_div">
                <v-divider class="booking_divider mx-4"></v-divider>
              </div> -->
            <div class="header_content white--text">
              <p style="margin-bottom: 0px">
                {{
                  Object.hasOwnProperty.call(bookingDetails, 'booking') &&
                    Object.hasOwnProperty.call(bookingDetails.booking, 'header')
                    ? bookingDetails.booking.header
                    : ""
                }}
              </p>
            </div>
          </div>
          <v-card-title class="pa-1"></v-card-title>
          <v-card-text class="pa-2">
            <v-row align="center" class="mx-0 pb-2" no-gutters> </v-row>
            <v-row no-gutters class="mt-1 mx-0">
              <v-col cols="4" class="text-left pa-1">
                <p class="booking_date_header mb-0">Mobile No:</p>
              </v-col>
              <v-col class="text-right pa-1">
                <p class="booking_date mb-0">
                  {{
                    Object.hasOwnProperty.call(bookingDetails, 'booking') &&
                      Object.hasOwnProperty.call(bookingDetails.booking, 'jsonArr')
                      ? bookingDetails.booking["jsonArr"]["Mobile No"]
                      : ""
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-1 mx-0">
              <v-col cols="4" class="text-left pa-1">
                <p class="booking_date_header mb-0">Plate:</p>
              </v-col>
              <v-col class="text-right pa-1">
                <p class="booking_date mb-0">
                  {{
                    Object.hasOwnProperty.call(bookingDetails, 'booking') &&
                      Object.hasOwnProperty.call(bookingDetails.booking, 'vehicleNo')

                      ? bookingDetails.booking.vehicleNo.toUpperCase() || "-"
                      : ""
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-1 mx-0">
              <v-col cols="4" class="text-left pa-1">
                <p class="booking_date_header mb-0">Valid From:</p>
              </v-col>
              <v-col class="text-right pa-1">
                <p class="booking_date mb-0">
                  {{
                    Object.hasOwnProperty.call(bookingDetails, 'booking') &&
                      Object.hasOwnProperty.call(bookingDetails.booking, 'jsonArr')
                      ? bookingDetails.booking["jsonArr"]["Start Date"]
                      : ""
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters class="mx-0">
              <v-col cols="4" class="text-left pa-1">
                <p class="booking_date_header">Valid Until:</p>
              </v-col>
              <v-col class="text-right pa-1">
                <p class="booking_date">
                  {{
                    Object.hasOwnProperty.call(bookingDetails, 'booking') &&
                      Object.hasOwnProperty.call(bookingDetails.booking, 'jsonArr')
                      ? bookingDetails.booking["jsonArr"]["End Date"]
                      : ""
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row class="justify-center mb-3" no-gutters
              v-show="(isCheckoutEnabled(1) || isCheckoutEnabled(2) || isCheckoutEnabled(3))">
              <span class="grey--text text--darken-1 font-weight-bold">Click an option below to enter</span>
            </v-row>

            <v-row class="justify-center my-3" no-gutters
              v-show="!(isCheckoutEnabled(1) || isCheckoutEnabled(2) || isCheckoutEnabled(3))">
              <span class="black--text text--darken-1 font-weight-bold text-h5">Ready to Check-in</span>
            </v-row>

          </v-card-text>
          <!-- <v-card-text class="text-center">Click an option to exit</v-card-text> -->
          <v-card-actions class="justify-center pb-10"
            v-show="(isCheckoutEnabled(1) || isCheckoutEnabled(2) || isCheckoutEnabled(3))">
            <v-row no-gutters class="text-center px-10">
              <v-col v-if="isCheckoutEnabled(1)">
                <v-avatar color="red" class="pa-6">
                  <v-icon medium color="white accent-2" class="px-4" dark @click="openSMSDialog()"
                    :disabled="checkStartDate()">{{
                    getExitIcon(1) }}</v-icon>
                </v-avatar>
                <p class="grey--text text--darken-1 entry-option-text">
                  {{ getExitText(1) }}
                </p>
              </v-col>
              <v-col v-if="isCheckoutEnabled(2)">
                <v-avatar color="red" class="pa-6">
                  <v-icon medium color="white accent-2" class="px-4" dark @click.stop="qrCodeDialog = true"
                    :disabled="checkStartDate()">{{ getExitIcon(2) }}</v-icon>
                </v-avatar>
                <p class="grey--text text--darken-1 entry-option-text">
                  {{ getExitText(2) }}
                </p>
              </v-col>
              <v-col v-if="isCheckoutEnabled(3)">
                <v-avatar color="red" class="pa-6">
                  <v-icon medium color="white accent-2" class="px-4" @click="dialerDialog = true" dark
                    :disabled="checkStartDate()">{{ getExitIcon(3) }}</v-icon>
                </v-avatar>
                <p class="grey--text text--darken-1 entry-option-text">
                  {{ getExitText(3) }}
                </p>
              </v-col>
              <!-- <v-col>
                  <v-avatar color="red" :class="{ 'pulse-button pa-6': toggle }">
                    <v-icon
                      medium
                      color="white accent-2"
                      class="pulse-button px-4"
                      style="webkit-animation-name: pulse"
                      dark
                      @click.stop="
                        toggle
                          ? endSpeechRecognition()
                          : startAudio(
                              `Hi` +
                                bookingDetails.user.name +
                                `, Welcome to ` +
                                bookingDetails.zone.zoneName +
                                `. Please say the gate code.`
                            )
                      "
                      :disabled="checkStartDate()"
                      >{{ toggle ? "mic_off" : "mic" }}</v-icon
                    >
                  </v-avatar>
                  <p class="grey--text text--darken-1 entry-option-text">
                    Speech
                  </p>
                </v-col> -->
            </v-row>

          </v-card-actions>
          <v-col cols="12" class="mt-10 pa-0"
            v-if="(bookingDetails.hasOwnProperty('booking') &&
              bookingDetails.booking.hasOwnProperty('validated') &&
              bookingDetails.booking.validated != '1' &&
              bookingDetails.booking.isValidationEnabled == '1') || Object.hasOwnProperty.call(bookingDetails, 'zone') && Object.hasOwnProperty.call(bookingDetails.zone, 'isLoyaltySupported') && bookingDetails.zone.isLoyaltySupported == '1' && bookingDetails.booking.validated != '1' && (bookingDetails.booking.TypeOfBooking == 'Guest' || bookingDetails.booking.TypeOfBooking == 'Transient' || bookingDetails.booking.TypeOfBooking == 'Self reservation')">
            <hr style="color: black !important;">
            <v-card flat color="#ececec" class="ma-0 rounded-lg" tile max-width="100%">
              <v-card-text class="pb-0">
                <v-row class="text-center pt-0 pb-6" no-gutters>
                  <v-col cols="12" class="pa-0">
                    <p style="font-size: 15px;color: #F2555C;font-weight: bolder;">CLAIM
                      YOUR PARKING DISCOUNT</p>
                  </v-col>
                  <!-- <v-col cols="12"
                                                    v-if="Object.hasOwnProperty.call(bookingDetails, 'zone') && Object.hasOwnProperty.call(bookingDetails.zone, 'isLoyaltySupported') && bookingDetails.zone.isLoyaltySupported == '1' && bookingDetails.booking.validated != '1' && (bookingDetails.booking.TypeOfBooking == 'Guest' || bookingDetails.booking.TypeOfBooking == 'Transient')">
                                                    <v-btn color="black" class="pa-0 ma-0" rounded width="270" height="70"
                                                        elevation="20" @click="loyalty = true">
                                                        <v-avatar tile width="200" height="100">
                                                            <v-img src="../assets/GNLoyalty.png" height="60px"
                                                                width="400px"></v-img>
                                                        </v-avatar>
                                                    </v-btn>
                                                </v-col> -->
                  <v-col cols="12"
                    v-if="Object.hasOwnProperty.call(bookingDetails, 'zone') && Object.hasOwnProperty.call(bookingDetails.zone, 'isLoyaltySupported') && bookingDetails.zone.isLoyaltySupported == '1' && bookingDetails.booking.validated != '1' && (bookingDetails.booking.TypeOfBooking == 'Guest' || bookingDetails.booking.TypeOfBooking == 'Transient' || bookingDetails.booking.TypeOfBooking == 'Self reservation')">
                    <v-btn color="black" rounded class="pa-0 ma-0" width="230" height="50" elevation="20"
                      @click="loyalty = true">
                      <v-img v-if="
                                Object.hasOwnProperty.call(
                                  bookingDetails,
                                  'zone'
                                ) &&
                                Object.hasOwnProperty.call(
                                  bookingDetails.zone,
                                  'loyaltyFlags'
                                ) &&
                                Object.hasOwnProperty.call(
                                  bookingDetails.zone.loyaltyFlags,
                                  'logo_path'
                                )
                              "
                              :src="logoPath" style="border-radius: 1.2rem;" class max-width="230"
                        height="60"></v-img>
                        <v-img
                              v-else
                              src="../assets/newGoldenNugget.jpg"
                              style="border-radius: 1.2rem"
                              class
                              max-width="230"
                              height="60"
                            ></v-img>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="pt-4 px-4 text-center" v-show="bookingDetails.hasOwnProperty('booking') &&
                    bookingDetails.booking.hasOwnProperty('validated') &&
                    bookingDetails.booking.validated != '1' &&
                    bookingDetails.booking.isValidationEnabled == '1'
                    ">
                    <v-btn rounded elevation="20" dense width="235" height="40px" style="font-size:15px"
                      class="validate_btn white--text blue darken-2 no-upper-case px-4"
                      @click="openValidationDialog()">Apply
                      Your Validation</v-btn>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
          </v-col>
          <!-- <v-row v-if="showPaymentCardAdded">
              <v-col>
                <v-icon
                  x-small
                  color="green"
                  class="px-4"
                  dark
                  @click="navigateToCheckOut"
                >mdi-exit-to-app</v-icon>
              </v-col>
            </v-row>-->
          <!-- </v-responsive> -->
        </v-card>
      </v-col>
    </v-row>
    <div>
      <v-dialog v-model="loyalty" width="750px">
        <v-card>
          <v-row no-gutters :style="{
            'background-color': dialogLogoPath.includes('resorts')
              ? '#1581bf'
              : 'black',
          }" class="text-center">
            <v-col class="text-center pt-1 ">
              <img
              v-if="
                Object.hasOwnProperty.call(bookingDetails, 'zone') &&
                Object.hasOwnProperty.call(
                  bookingDetails.zone,
                  'loyaltyFlags'
                ) &&
                Object.hasOwnProperty.call(
                  bookingDetails.zone.loyaltyFlags,
                  'dialog_logo_path'
                )
              "
              :src="dialogLogoPath"
              width="200px"
              height="65px"
            />
            <img
              v-else
              src="../assets/GNLoyalty.png"
              width="200px"
              height="65px"
            />
            </v-col>
          </v-row>
          <v-card-text>
            <v-row no-gutters class="mt-4">
              <v-col>
                <p style="font-size: large; color: black">{{ loyaltyText }}</p>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-2">
              <v-col>
                <v-text-field :label="loyaltyLabelText" outlined v-model="loyalty24knum"
                  @keyup="loyaltyValid"></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-2 text-center">
              <v-col>
                <v-btn color="#F2555C" width="180" rounded class="white--text" :disabled="disableLoyalty"
                  @click="submitLoyalty(loyalty24knum)">Submit</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="validateQRCodeDialog" width="fit-content" persistent>
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-icon @click="closeValidationQRDialog()">mdi-close</v-icon>
          </v-card-title>
          <v-card-text class="pa-1">
            <validation-qr-code-scanner :scan="'validationCode'" v-bind:qrbox="250" v-bind:fps="30"
              :cameraStatus="validationCameraStatus"></validation-qr-code-scanner>
            <v-progress-circular v-if="!validationCameraStatus" class="validation-pending" indeterminate
              color="red"></v-progress-circular>
          </v-card-text>
          <v-card-actions>
            <v-container class="pa-0" fluid>
              <v-row no-gutters>
                <v-col cols="7">
                  <p class="grey--text font-weight-bold qr_text mr-4">Please scan the validation QR code
                  </p>
                </v-col>
                <v-spacer></v-spacer>
                <v-img src="../assets/qr-code.png" style="opacity: 0.7" max-width="52"></v-img>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-dialog v-model="validateBookingDialog" width="fit-content">
      <v-card class="pa-0" ref="validatecontainer">
        <v-card-title class="justify-center" style="position: relative">
          <v-btn class elevation="0" color="white" dark fab large>
            <v-icon x-large color="red accent-2">mdi-ticket-percent</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-container class="px-4 py-0" fluid>
            <v-row no-gutters>
              <v-col cols="12" class="text-left">
                <p class="color-black text-center" style="font-size: 24px; line-height: 35px">
                  If the Merchant provided you a validation code, you may scan
                  it or enter it here.
                </p>
              </v-col>
              <v-col cols="12">
                <v-btn rounded block elevation="20" class="white--text exit_dialog_btn"
                  @click="openScanValidationCode()">Scan Validation Code</v-btn>
              </v-col>
              <v-col cols="12" class="text-left mt-4">
                <p class="color-black" style="font-size: 24px; line-height: 35px">or enter it below</p>
              </v-col>
              <v-col cols="12">
                <v-text-field class="sms-input" color="red" v-model="validateBookingTxt" outlined
                  label="Validation code" hide-details="auto" v-on:keyup.enter="validateBooking()"
                  @focus="scrollToValidateBtn()" @mouseup="scrollToValidateBtn()" @keydown="scrollToValidateBtn()"
                  clearable height="80px"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-progress-circular v-if="validateBookingPending" class="validation-pending" indeterminate
            color="red"></v-progress-circular>
        </v-card-text>
        <v-card-actions class="text-center">
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn id="validatebookingbtn" rounded block elevation="20" :disabled="validateBookingTxt == '' ||
                  validateBookingTxt == null ||
                  validateBookingPending
                  " class="white--text exit_dialog_btn" @click="onValidateText()">SUBMIT</v-btn>
              </v-col>
              <v-col cols="12" class="mt-2">
                <p style="cursor: pointer" @click="validateBookingDialog = false">
                  Close
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="gateCodeSpeechDialog" width="fit-content">
      <v-card class="pa-0">
        <v-card-title class="justify-center" style="position: relative">
          <v-btn class elevation="0" color="white" dark fab large :class="{ 'animated infinite pulse': toggle }">
            <v-icon x-large color="red accent-2">mic</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-container fluid>
            <v-row no-gutters>
              <v-col cols="12" class="text-left">
                <p class="color-black text-center" style="font-size: 24px; line-height: 35px">
                  Please say the gate code
                </p>
              </v-col>
              <v-col cols="12">
                <v-text-field class="speech-input" color="red" autofocus v-model="runtimeTranscription" outlined
                  label="Gate code" hide-details="auto" height="80px"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-progress-circular v-if="sendGateCodePending" class="validation-pending" indeterminate
            color="red"></v-progress-circular>
        </v-card-text>
        <v-card-actions class="text-center">
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12" class="mt-2">
                <p style="cursor: pointer" @click="gateCodeSpeechDialog = false">
                  Close
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="qrCodeDialog" width="fit-content" persistent>
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon @click="qrCodeDialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-1">
          <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
            <v-progress-circular v-if="validationPending" class="validation-pending" indeterminate
              color="red"></v-progress-circular>
          </qrcode-stream>
        </v-card-text>
        <v-card-actions>
          <v-container class="pa-0" fluid>
            <v-row no-gutters>
              <v-col cols="7">
                <p class="grey--text font-weight-bold qr_text mr-4">
                  Scan the QR code affixed to enter the gate
                </p>
              </v-col>
              <v-spacer></v-spacer>
              <v-img src="../assets/qr-code.png" style="opacity: 0.7" max-width="52"></v-img>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="smsDialog" width="fit-content">
      <v-card class="pa-0" ref="smscontainer">
        <v-card-title class="justify-center" style="position: relative">
          <v-btn class elevation="0" color="white" dark fab x-large>
            <v-icon x-large color="red accent-2">mdi-message-text</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-container class="px-4" fluid>
            <v-row no-gutters>
              <v-col cols="12" class="text-left">
                <p class="color-black" style="font-size: 24px; line-height: 35px">
                  Enter the gate code shown in the lane to enter.
                </p>
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-text-field class="sms-input" color="red" v-model="smsText" outlined label="Gate code" maxlength="6"
                  type="tel" autofocus v-on:keyup.enter="openSMSApp()" @focus="scrollToSubmit()"
                  @mouseup="scrollToSubmit()" @keydown="scrollToSubmit()" clearable height="80px"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-progress-circular v-if="smsPending" class="validation-pending" indeterminate
            color="red"></v-progress-circular>
        </v-card-text>
        <v-card-actions class="text-center">
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn id="submitsmsbtn" rounded block elevation="20" :disabled="smsText == '' || smsPending"
                  class="white--text entry_dialog_btn" @click="openSMSApp()">SUBMIT</v-btn>
              </v-col>
              <v-col cols="12" class="mt-2">
                <p style="cursor: pointer" @click="smsDialog = false">Close</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialerDialog" width="fit-content">
      <v-card class="pa-0">
        <v-card-title class="justify-center" style="position: relative">
          <v-btn class elevation="0" color="white" dark fab x-large>
            <v-icon x-large color="red accent-2">mdi-phone-in-talk</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-container class="px-4" fluid>
            <v-row no-gutters>
              <v-col cols="12" class="text-left">
                <p class="color-black" style="font-size: 24px; line-height: 35px">
                  Tap to call the gate. When prompted, enter the gate code shown
                  in the lane to enter.
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="text-center">
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn rounded block elevation="20" class="white--text entry_dialog_btn" @click="openDialerApp">CALL
                  GATE</v-btn>
              </v-col>
              <v-col cols="12" class="mt-2">
                <p style="cursor: pointer" @click="dialerDialog = false">
                  Close
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="invalidQRDialog" persistent max-width="290">
      <v-card class="pa-0">
        <v-card-title class="justify-center" style="position: relative">
          <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-text class="text-center mt-5">
          <span style="font-size: 20px">{{ invalidQRMsg }}</span>
        </v-card-text>
        <v-card-actions class="text-center pb-6">
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn rounded block elevation="20" class="white--text entry_dialog_btn" @click="
                  invalidQRDialog = false;
                turnCameraOn();
                ">Re-Scan Code</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="invalidSMSDialog" persistent max-width="290">
      <v-card class="pa-0">
        <v-card-title class="justify-center" style="position: relative">
          <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-text class="text-center mt-5">
          <span style="font-size: 20px">{{ invalidSMSMsg }}</span>
        </v-card-text>
        <v-card-actions class="text-center pb-6">
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn rounded block elevation="20" class="white--text entry_dialog_btn"
                  @click="invalidSMSDialog = false">Re-Enter Code</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import APIHelper from "../apiHelper";
// import loggerHelper from "../loggerHelper";
let SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
let recognition = SpeechRecognition ? new SpeechRecognition() : false;
import { mapGetters } from "vuex";
import { EventBus } from "@/lib/EventBus";
import { QrcodeStream } from "vue-qrcode-reader";
import ValidationQrCodeScanner from "@/views/ValidationQrCodeScanner.vue";

export default {
  name: "CheckIn",
  components: { QrcodeStream, ValidationQrCodeScanner },
  data: () => ({
    loading: false,
    loyalty: false, loyalty24knum: "",
    invalidValidationQRDialog: false,
    cameraStatus: false,
    validationCameraStatus: false,
    validatedDateTime: 0,
    gracePeriodTime: 0,
    validatedInterval: "",
    validateBookingPending: false,
    validateBookingDialog: false,
    validateBookingTxt: "",
    validateQRCodePending: false,
    validateQRCodeDialog: false,
    sendGateCodePending: false,
    duration: 300,
    offset: 0,
    easing: "easeInOutCubic",
    camera: "auto",
    smsText: "",
    smsDialog: false,
    qrCodeDialog: false,
    dialerDialog: false,
    invalidQRMsg: "",
    invalidQRDialog: false,
    invalidSMSMsg: "",
    invalidSMSDialog: false,
    isValid: undefined,
    smsPending: false,
    gateCodeSpeechDialog: false,
    lang: "en-US",
    error: false,
    speaking: false,
    toggle: false,
    runtimeTranscription: "",
    sentences: [],
    synth: window.speechSynthesis,
    voiceList: [],
    greetingSpeech: new window.SpeechSynthesisUtterance(),
  }),
  computed: {
    ...mapGetters({
      bookingId: "getterBookingId",
      bookingDetails: "getterBookingDetails",
      entryTime: "getterEntryTime",
      entryGateCodes: "getterEntryGateCodes"
    }),
    disableLoyalty() {
      let disabled = this.loyalty24knum === undefined || this.loyalty24knum === null || this.loyalty24knum == ""
      return disabled
    },
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
      };
    },

    validationPending: function qrValidation() {
      return this.isValid === undefined && this.camera === "off";
    },
    loyaltyText() {
      return this.bookingDetails?.zone?.loyaltyFlags?.dialog_text !== null &&
        this.bookingDetails?.zone?.loyaltyFlags?.dialog_text !== undefined &&
        this.bookingDetails?.zone?.loyaltyFlags?.dialog_text !== ""
        ? this.bookingDetails?.zone?.loyaltyFlags?.dialog_text
        : "Enter your Loyalty Card Number";
    },
    loyaltyLabelText(){
      return this.bookingDetails?.zone?.loyaltyFlags?.label_text !== null &&
        this.bookingDetails?.zone?.loyaltyFlags?.label_text !== undefined &&
        this.bookingDetails?.zone?.loyaltyFlags?.label_text !== ""
        ? this.bookingDetails?.zone?.loyaltyFlags?.label_text
        : "Enter your Loyalty Card Number";
    },
    logoPath() {
      return this.bookingDetails?.zone?.loyaltyFlags?.logo_path !== null &&
        this.bookingDetails?.zone?.loyaltyFlags?.logo_path !== undefined &&
        this.bookingDetails?.zone?.loyaltyFlags?.logo_path != ""
        ? this.bookingDetails?.zone?.loyaltyFlags?.logo_path
        : "../assets/newGoldenNugget.jpg";
    },
    dialogLogoPath() {
      return this.bookingDetails?.zone?.loyaltyFlags?.dialog_logo_path !==
        null &&
        this.bookingDetails?.zone?.loyaltyFlags?.dialog_logo_path !==
          undefined &&
        this.bookingDetails?.zone?.loyaltyFlags?.dialog_logo_path != ""
        ? this.bookingDetails?.zone?.loyaltyFlags?.dialog_logo_path
        : "../assets/newGoldenNugget.jpg";
    },
  },
  watch: {},
  created() { },
  mounted() {
    EventBus.$on("validation-qr-result", (result) => {
      this.onValidateQRCode(result);
    });
    // this.checkCompatibility();
  },
  destroyed() { },
  beforeRouteEnter(from, to, next) {
    next(async (vm) => {
      vm.getBookingDetails();
    });
  },
  methods: {
    async submitLoyalty(code) {
      try {
        this.validateBookingPending = true;
        let result = await this.validateBooking(code);
        if (!result.data.status) {
          this.invalidSMSMsg = result?.data?.message
            ? result.data.message
            : "Validation not recognized. Please try again";
          this.invalidSMSDialog = true;
          this.validateBookingPending = false;
          this.postToLoggerAPI({
            phone: this.bookingDetails.user.contact,
            refId: this.bookingId,
            error: this.invalidSMSMsg,
            icon: "redeem",
            statusText:
              "Error in validating the session on entering validation code - " +
              this.validateBookingTxt,
          });
        } else {
          this.postToLoggerAPI({
            phone: this.bookingDetails.user.contact,
            refId: this.bookingId,
            icon: "redeem",
            statusText:
              "Parker validated the session successfully by entering validation code - " +
              this.validateBookingTxt,
          });
          this.validateBookingPending = false;
          this.validateBookingDialog = false;
          this.$store.commit(
            "SET_VALIDATED_MERCHANT",
            result?.data?.merchantName
          );
          this.$router.replace({ path: "/validatesuccess" });
        }
      } catch (error) {
        console.log(error);
        this.validateBookingPending = false;
      }
    },
    loyaltyValid() {
      if (!(/^[a-z0-9]+$/i).test(this.loyalty24knum)) {
        this.loyalty24knum = this.loyalty24knum.replace(/[^\w\s]/gi, '')
      }
    },
    closeValidationQRDialog() {
      this.validationCameraStatus = false;
      this.validateBookingDialog = true;
      this.validateQRCodeDialog = false;
    },
    reScanValidationQR() {
      this.invalidValidationQRDialog = false;
      this.validationCameraStatus = true;
    },
    async onValidateText() {
      this.validateBookingPending = true;
      let result = await this.validateBooking(this.validateBookingTxt);
      if (!result.data.status) {
        this.invalidSMSMsg = result?.data?.message
          ? result.data.message
          : "Validation not recognized. Please try again";
        this.invalidSMSDialog = true;
        this.validateBookingPending = false;
        this.postToLoggerAPI({
          phone: this.bookingDetails.user.contact,
          refId: this.bookingId,
          error: this.invalidSMSMsg,
          icon: "redeem",
          statusText:
            "Error in validating the session on entering validation code - " +
            this.validateBookingTxt,
        });
      } else {
        this.postToLoggerAPI({
          phone: this.bookingDetails.user.contact,
          refId: this.bookingId,
          icon: "redeem",
          statusText:
            "Parker validated the session successfully by entering validation code - " +
            this.validateBookingTxt,
        });
        this.goldenNugget = false;
        this.validateBookingPending = false;
        this.validateBookingDialog = false;
        this.$store.commit(
          "SET_VALIDATED_MERCHANT",
          result?.data?.merchantName
        );
        this.$router.replace({ path: "/validatesuccess" });
      }
    },
    async validateBooking(code) {
      try {
        let validateResult = await APIHelper(
          "POST",
          "/api/v1/validate/" + this.bookingId,
          {
            coupon: code,
            zid: this.bookingDetails.zone.locationCode
          }
        );
        return validateResult;
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async onValidateQRCode(decodedString) {
      this.validationCameraStatus = false;
      this.validateQRCodePending = true;
      try {
        let result = await this.validateBooking(decodedString);
        if (!result.data.status) {
          this.invalidValidationQRMsg = result?.data?.message
            ? result.data.message
            : "Validation not recognized. Please try again";
          this.invalidValidationQRDialog = true;
          this.validateQRCodePending = false;
          this.validateQRCodePending = false;
          this.validateQRCodeDialog = false;
          this.postToLoggerAPI({
            phone: this.bookingDetails.user.contact,
            refId: this.bookingId,
            error: this.invalidQRMsg,
            icon: "redeem",
            statusText:
              "Error in validating the session on scanning validation QR - " +
              decodedString,
          });
        } else {
          this.postToLoggerAPI({
            phone: this.bookingDetails.user.contact,
            refId: this.bookingId,
            icon: "redeem",
            statusText:
              "Parker validated the session successfully by scanning validation QR - " +
              decodedString,
          });
          this.validateQRCodePending = false;
          this.validateQRCodeDialog = false;
          this.$store.commit(
            "SET_VALIDATED_MERCHANT",
            result?.data?.merchantName
          );
          this.$router.replace({ path: "/validatesuccess" });
        }
      } catch (error) {
        console.log(error);
      }
    },
    openValidationDialog() {
      this.validateBookingDialog = true;
    },
    async openScanValidationCode() {
      this.validateBookingDialog = false;
      this.validateBookingTxt = "";
      this.validateQRCodeDialog = true;
      this.validationCameraStatus = true;
    },
    openSMSDialog() {
      this.smsDialog = true;
      if (this.bookingDetails?.booking?.prePopulateGateCodes && this.bookingDetails.booking.prePopulateGateCodes == '1') {
        this.smsText = this.entryGateCodes.length == 1 ? this.entryGateCodes[0].sms_code : "";
      }
    },
    checkStartDate() {
      return false;
    },
    scrollToSubmit() {
      const el = document.querySelector("#submitsmsbtn");
      if (el) {
        el.scrollIntoView(this.options);
      }
    },
    scrollToValidateBtn() {
      const el = document.querySelector("#validatebookingbtn");
      if (el) {
        el.scrollIntoView(this.options);
      }
    },
    checkCompatibility() {
      if (!recognition) {
        this.error =
          "Speech Recognition is not available on this browser. Please use Chrome or Firefox";
      }
    },
    isCheckoutEnabled(type) {
      let option = this.bookingDetails?.exitOptions
        ? this.bookingDetails.exitOptions.some((t) => t.techType == type)
        : "";
      return option;
    },
    getExitIcon(exitId) {
      let exitDetails = this.bookingDetails?.exitOptions
        ? this.bookingDetails.exitOptions.find((p) => p.techType == exitId)
        : "";
      return exitDetails.icon;
    },
    async openSMSApp() {
      if (this.smsText == null || this.smsText == "") {
        this.invalidSMSMsg = "Gate code cannot be empty";
        this.invalidSMSDialog = true;
      } else {
        try {
          this.smsPending = true;
          var preValidateSendSMS = await APIHelper("POST", "/receive_sms", {
            bid: this.bookingId,
            From: this.bookingDetails.user.contact,
            Text: this.smsText,
            To: this.bookingDetails.zone.ToMobileNo,
            Mode: "SMS",
            webView: "1",
            preValidate: 1,
          });
          this.smsPending = false;
          if (
            preValidateSendSMS?.data?.status &&
            preValidateSendSMS.data.status
          ) {
            try {
              var sendSMS = await APIHelper("POST", "/receive_sms", {
                bid: this.bookingId,
                From: this.bookingDetails.user.contact,
                Text: this.smsText,
                To: this.bookingDetails.zone.ToMobileNo,
                Mode: "SMS",
                webView: "1",
              });
              if (sendSMS?.data?.status && sendSMS.data.status) {
                this.smsDialog = false;
                this.$router.replace({ path: "/checkinsuccess" });
              } else {
                this.invalidSMSMsg = sendSMS?.data?.message
                  ? sendSMS.data.message
                  : "Please try again";
                this.invalidSMSDialog = true;
              }
            } catch (error) {
              console.log(error);
            }
          } else {
            this.invalidSMSMsg = preValidateSendSMS?.data?.message
              ? preValidateSendSMS.data.message
              : "Please try again";
            this.invalidSMSDialog = true;
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }
      return "unknown";
    },
    openDialerApp() {
      this.dialerDialog = false;
      var url = "tel:" + this.bookingDetails.zone.ToMobileNo;
      window.location.href = url;
      if (this.getMobileOperatingSystem() == "Android") {
        window.close();
      }
    },
    turnCameraOn() {
      this.camera = "auto";
    },

    turnCameraOff() {
      this.camera = "off";
    },
    async sendGateCode() {
      this.endSpeechRecognition();
      let gateCodeText = this.runtimeTranscription;
      try {
        this.sendGateCodePending = true;
        var preValidateSendGateCode = await APIHelper("POST", "/receive_sms", {
          bid: this.bookingId,
          From: this.bookingDetails.user.contact,
          Text: gateCodeText,
          To: this.bookingDetails.zone.ToMobileNo,
          Mode: "SMS",
          webView: "1",
          preValidate: 1,
        });
        this.sendGateCodePending = false;
        this.toggle = false;
        if (
          preValidateSendGateCode?.data?.status &&
          preValidateSendGateCode.data.status
        ) {
          try {
            var sendSMS = await APIHelper("POST", "/receive_sms", {
              bid: this.bookingId,
              From: this.bookingDetails.user.contact,
              Text: gateCodeText,
              To: this.bookingDetails.zone.ToMobileNo,
              Mode: "SMS",
              webView: "1",
            });
            if (sendSMS?.data?.status) {
              this.gateCodeSpeechDialog = false;
              this.$router.replace({ path: "/checkinsuccess" });
            } else {
              this.invalidSMSMsg = sendSMS?.data?.message
                ? sendSMS.data.message
                : "Please try again";
              this.invalidSMSDialog = true;
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          this.invalidSMSMsg = preValidateSendGateCode?.data?.message
            ? preValidateSendGateCode.data.message
            : "Please try again";
          this.startAudio(this.invalidSMSMsg);
          // this.invalidSMSDialog = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getBookingDetails() {
      this.loading = true;
      try {
        let bookingId = window.location.pathname.substring(3)
                var bookingDetails = await APIHelper(
                    "GET",
                    "/api/v1/booking/config/" + bookingId
                );
        this.$store.commit(
          "SET_BOOKING_DETAILS",
          bookingDetails.data?.data ? bookingDetails.data.data : null
        );
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async onDecode(decodedString) {
      var self = this;
      this.qrCode = decodedString;
      this.turnCameraOff();
      try {
        var preValidateQrResult = await APIHelper(
          "POST",
          "/api/v1/checkout/" + this.bookingId,
          {
            beaconId: decodedString,
            preValidate: 1,
            zid: this.bookingDetails.zone.locationCode
          }
        );
        if (preValidateQrResult.data.status) {
          try {
            self.$router.replace({ path: "/checkinsuccess" });
            var qrResult = await APIHelper(
              "POST",
              "/api/v1/checkout/" + this.bookingId,
              {
                beaconId: decodedString,
                zid: this.bookingDetails.zone.locationCode
              }
            );
            if (qrResult.data.status) {
              console.log(qrResult)
            } else {
              this.invalidQRMsg = qrResult?.data?.message
                ? qrResult.data.message
                : "Please try again";
              this.invalidQRDialog = true;
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          this.invalidQRMsg = preValidateQrResult?.data?.message
            ? preValidateQrResult.data.message
            : "Please try again";
          this.invalidQRDialog = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.invalidQRMsg = "Please grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.invalidQRMsg = "No camera found on this device";
        } else if (error.name === "NotSupportedError") {
          this.invalidQRMsg = "Secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.invalidQRMsg = "Is the camera already in use? Please check.";
        } else if (error.name === "OverconstrainedError") {
          this.invalidQRMsg = "Installed camera is not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.invalidQRMsg = "Stream API is not supported in this browser";
        }
        this.invalidQRDialog = true;
      }
    },
    endSpeechRecognition() {
      recognition.stop();
      this.synth.cancel();
      this.toggle = false;
      // this.$emit("speechend", {
      //   sentences: this.sentences,
      //   text: this.sentences.join(". "),
      // });
    },
    startAudio(speechText) {
      this.gateCodeSpeechDialog = true;
      // console.log(recognition)
      this.voiceList = this.synth.getVoices();
      // this.greetingSpeech.text = `Hi Rajesh Kola, Welcome to commerce B Garage. Please say the gate code.`
      this.greetingSpeech.voice = this.voiceList[1];
      // this.synth.speak(this.greetingSpeech);
      const audio = new SpeechSynthesisUtterance(speechText);
      audio.onstart = () => console.log(audio.text);
      this.synth.speak(audio);
      audio.onend = () => this.startSpeechRecognition();
    },
    startSpeechRecognition() {
      var self = this;
      if (!recognition) {
        this.error =
          "Speech Recognition is not available on this browser. Please use Chrome or Firefox";
        return false;
      }
      this.toggle = true;
      recognition.lang = this.lang;
      recognition.interimResults = true;

      recognition.addEventListener("speechstart", () => {
        self.speaking = true;
      });

      recognition.addEventListener("soundend", () => {
        self.speaking = false;
      });
      recognition.addEventListener("speechend", () => {
        self.speaking = false;
      });

      recognition.addEventListener("result", (event) => {
        const text = Array.from(event.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");
        self.runtimeTranscription = text;
      });

      recognition.addEventListener("end", (event) => {
        console.log(event);
        if (self.runtimeTranscription !== "") {
          self.sentences.push(self.runtimeTranscription);
          // self.$emit(
          //   "update:text",
          //   `${self.text}${self.sentences.slice(-1)[0]}. `
          // );
        }

        self.runtimeTranscription == "" ? "" : self.sendGateCode(); //&& self.synth.cancel();
        self.runtimeTranscription == ""
          ? ""
          : (self.gateCodeSpeechDialog = false);
        self.runtimeTranscription = "";
        recognition.stop();
        // self.endSpeechRecognition()
        // self.sentences =[];
        if (self.toggle) {
          // keep it going.
          recognition.start();
        }
      });
      recognition.start();
    },
    getExitText(exitId) {
      let exitDetails = this.bookingDetails?.exitOptions
        ? this.bookingDetails.exitOptions.find((p) => p.techType == exitId)
        : "";
      return exitDetails.name;
    },
    speechEnd({ sentences, text }) {
      console.log(text)
      this.sentences = sentences;
    },
  },
};
</script>
<style lang="less">
.booking_header {
  position: absolute;
  bottom: 60px;
  left: 16px;
  font-size: 18px;
  font-weight: bolder;
}

.booking_divider {
  border-color: white !important;
  margin-top: 12px;
}

.booking_divider_div {
  position: absolute;
  bottom: 45px;
  width: 100%;
}

.booking_date {
  font-weight: normal;
  font-size: 13px;
  color: #3d3f3c !important;
}

.booking_date_header {
  color: #3d3f3c !important;
  font-weight: bold;
  font-size: 14px;
}

.header_content {
  position: absolute !important;
  bottom: 18px !important;
  left: 16px !important;
  font-size: 18px !important;
  font-weight: bolder !important;
}

.speech-input input {
  font-size: 36px;
  font-weight: 500;
  color: #44515a !important;
}

.entry-option-text {
  font-size: 14px;
}

.qr_text {
  font-size: 13px;
}

.sms-input input {
  font-size: 36px;
  font-weight: 500;
  color: #44515a !important;
}

.v-text-field--enclosed .v-input__append-inner {
  margin-top: 26px !important;
}

.sms-caption {
  font-size: 0.75rem !important;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.0333333333em !important;
}

.qrcode-stream-wrapper video {
  max-height: 300px;
}

.entry_dialog_btn {
  width: 100%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: normal !important;
  height: 50px !important;
}

.validation-pending {
  position: absolute !important;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.pulse-button {
  -webkit-animation: pulse 1.5s infinite;
}

.pulse-button:hover {
  -webkit-animation: none;
}

@-webkit-keyframes pulse {
  0% {
    @include transform(scale(0.9));
  }

  70% {
    @include transform(scale(1));
    box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
  }

  100% {
    @include transform(scale(0.9));
    box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
  }
}
</style>
